import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import MyAlbumPageStore from "../../store";
import { observer } from "mobx-react";
import ArrowButton from "./ArrowButton";

const FullscreenImageViewer: React.FC = observer(() => {
    const { expandImage, images, imageNumber, isFullScreen } = MyAlbumPageStore;

    if (!expandImage) return null;

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = expandImage.downloadUrl;
        link.setAttribute('download', `image-${imageNumber}.jpg`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handlePrevImage = () => {
        const currentIndex = images.findIndex(
            (image) => image.displayUrl === expandImage.displayUrl
        );
        if (currentIndex > 0) {
            MyAlbumPageStore.setExpandImage(images[currentIndex - 1]);
        }
    };

    const handleNextImage = () => {
        const currentIndex = images.findIndex(
            (image) => image.displayUrl === expandImage.displayUrl
        );
        if (currentIndex < images.length - 1) {
            MyAlbumPageStore.setExpandImage(images[currentIndex + 1]);
        }
    };

    return (
        <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "black",
                display: isFullScreen ? "flex" : "none",  // Toggle visibility
                justifyContent: "center",
                alignItems: "center",
                zIndex: isFullScreen ? 1300 : -1,  // Ensure the modal is above other content when visible
                visibility: isFullScreen ? "visible" : "hidden",  // Hide when not fullscreen
            }}
        >
            {/* Top Bar for Close Button, Image Number, and Download Button */}
            <Box
                sx={{
                    position: "absolute",
                    top: 16,
                    left: 16,
                    right: 16,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "calc(100% - 32px)",
                }}
            >
                <IconButton
                    onClick={() => MyAlbumPageStore.setIsFullScreen(false)}
                    sx={{
                        color: "white",
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography
                    variant="h6"
                    sx={{
                        color: "white",
                    }}
                >
                    {imageNumber}/{images.length}
                </Typography>
                <IconButton
                    onClick={handleDownload}
                    sx={{
                        color: "white",
                    }}
                >
                    <DownloadIcon />
                </IconButton>
            </Box>

            {/* Arrow Buttons */}
            {/* You can add similar logic for arrow buttons based on images length */}
            <ArrowButton direction="right" onClick={handlePrevImage} isVisible={imageNumber > 1} />
            <ArrowButton direction="left" onClick={handleNextImage} isVisible={imageNumber < images.length} />

            {/* Fullscreen Image */}
            <img
                src={expandImage.displayUrl}
                alt="Full screen"
                style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                }}
            />
        </Box>
    );
});

export default FullscreenImageViewer;
