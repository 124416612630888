import { Box, Typography } from "@mui/material";
import QrCode from "../../components/QrCode";
import CopyLinkCard from "../../components/CopyLinkCard";

interface EventLinksSectionProps {
    joinEventLinkUrl?: string;
}

const EventLinksSection: React.FC<EventLinksSectionProps> = ({ joinEventLinkUrl }) => {
    return (
        <Box>
            {
                joinEventLinkUrl &&
                <Box mt={5} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Box sx={{ flex: 1 }}>
                        <QrCode url={joinEventLinkUrl} />
                    </Box>
                    <Typography sx={{ marginX: 2 }}>או</Typography>
                    <Box sx={{ flex: 1, marginLeft: 2 }}>
                        <CopyLinkCard link={joinEventLinkUrl} />
                    </Box>
                </Box>
            }
        </Box>
    );
}

export default EventLinksSection;