import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Typography, Avatar, Divider, Button } from "@mui/material";
import { observer } from "mobx-react";
import { useFetchAlbum } from "./hooks/useFetchImages";
import defaultBackgroundImage from "../../assets/images/default_background_image.png";
import MyAlbumPageStore from "./store";
import BottomFooter from "./components/BottomFooter";
import MoreActionsDialog from "./components/MoreActionsDialog";
import FullscreenImageViewer from "./components/FullScreenImageViewer";
import ActionButton from "./components/ActionButton";
import { ArrowDownward, WhatsApp } from "@mui/icons-material";
import { getEventPinCodeFromCookie } from "./utils";
import NoImagesFound from "./components/NoImagesFound";
import ImagesList from "./components/ImagesList";
import Loader from "../../components/Loader";
import { formatDate } from "../../utils/formatDate";

export type AlbumImage = {
    displayUrl: string;
    downloadUrl: string;
    key: string;
};

export interface IMyEventAlbumPage {
    images: AlbumImage[];
    eventName: string;
    backgroundImageUrl: string;
    mainImageUrl: string;
    eventDate: string;
}

const MyAlbumPage: React.FC<{}> = observer(() => {
    const { eventId, phoneNumber } = useParams();
    const imagesListRef = useRef<HTMLDivElement | null>(null); // Ref for ImagesList component
    const selectButtonRef = useRef<HTMLButtonElement | null>(null); // Ref type should match the element type
    const navigate = useNavigate();

    if (!eventId || !phoneNumber) throw new Error("משהו השתבש אנא נסה שנית מאוחר יותר");

    const pinCode = getEventPinCodeFromCookie(eventId) || "";
    const { data, isLoading } = useFetchAlbum(eventId, phoneNumber, pinCode);

    useEffect(() => {
        MyAlbumPageStore.setImages(data?.images.filter(Boolean) || []);
    }, [data]);

    useEffect(() => {
        if (!pinCode) {
            navigate(`/pin-code/${eventId}/${phoneNumber}`);
        }
    }, [pinCode, navigate, eventId, phoneNumber]);

    useEffect(() => {
        if (!MyAlbumPageStore.isFullScreen) {
            // Restore scroll position when exiting fullscreen mode
            window.scrollTo(0, MyAlbumPageStore.scrollPosition);
        }
    }, [MyAlbumPageStore.isFullScreen]);

    const handleEnterFullScreen = (image: AlbumImage) => {
        MyAlbumPageStore.setExpandImage(image); // Set the selected image
        MyAlbumPageStore.setScrollPosition(window.scrollY);
        MyAlbumPageStore.setIsFullScreen(true);
    };

    const handleShareMultiple = () => {
        const pinCode = getEventPinCodeFromCookie(eventId) || "";
        if (navigator.share) {
            navigator.share({
                title: data?.eventName || 'האירוע שלי',
                text: `
היי, אני רוצה לשתף אותך באלבום שלי מהאירוע: ${data?.eventName}
הקוד לצפייה הוא: ${pinCode}
${window.location.href}
                `,
            }).catch(console.error);
        }
        MyAlbumPageStore.setIsMoreActionsDialogOpen(false);
    };

    const handleGlobalDownload = async () => {
        MyAlbumPageStore.handleGlobalDownload(eventId, phoneNumber, pinCode);
    };

    const handleSelectImages = () => {
        MyAlbumPageStore.setIsSelectImagesMode(!MyAlbumPageStore.isSelectImagesMode);
        MyAlbumPageStore.setSelectedImages([]);
    }

    if (isLoading) {
        return (
            <Grid container justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
                <Loader />
            </Grid>
        );
    }

    return (
        <>
            <FullscreenImageViewer />
            <Grid
                container
                justifyContent="center"
                sx={{
                    overflowX: 'hidden',
                    padding: 0,
                    margin: 0,
                }}
            >
                <Grid
                    item
                    xs={12} sm={10} md={8} lg={12}
                    style={{ position: 'relative' }}
                >
                    <Box
                        component="img"
                        src={data?.backgroundImageUrl || defaultBackgroundImage}
                        alt="Event"
                        sx={{
                            width: '100%',
                            height: { xs: '250px', sm: '350px', md: '450px' },
                            objectFit: 'cover',
                            margin: 0,
                        }}
                    />
                    {data?.mainImageUrl && (
                        <Avatar
                            alt="User"
                            src={data?.mainImageUrl}
                            sx={{
                                width: { xs: 80, sm: 100 },
                                height: { xs: 80, sm: 100 },
                                position: 'absolute',
                                bottom: -40,
                                left: 40,
                                border: '3px solid white',
                                backgroundColor: 'white',
                                objectFit: 'contain',
                                padding: 1,
                                boxShadow: '0px 12px 24px 0px rgba(18, 64, 31, 0.08)',
                            }}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={10} md={8} lg={6}>
                    <Box p={{ xs: 2, sm: 3 }} sx={{ textAlign: 'left', mt: 4 }}>
                        <Typography
                            sx={{
                                marginBottom: '2px',
                                fontWeight: '800',
                                background: 'linear-gradient(90deg, #FF77C0 100%, #8745F3 66%, #70D4FF 100%)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                backgroundClip: 'text',
                                textFillColor: 'transparent',
                                fontSize: "30px"
                            }}
                        >
                            {data?.eventName}
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            {formatDate(data?.eventDate || "")}
                        </Typography>
                        {
                            (MyAlbumPageStore.images.length === 0 && !isLoading) ? (
                                <NoImagesFound />
                            ) : (
                                <>
                                    <Typography variant="body1" sx={{ marginBottom: 3 }}>
                                        היי, הכנו לך אלבום אישי עם כל התמונות שלך מהאירוע
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <ActionButton
                                                label="הורדת האלבום"
                                                Icon={ArrowDownward}
                                                onClick={handleGlobalDownload}
                                                isLoading={MyAlbumPageStore.isGlobalDownloadLoading}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ActionButton
                                                label="שיתוף האלבום"
                                                Icon={WhatsApp}
                                                onClick={handleShareMultiple}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ height: "5px", my: 3 }} />
                                    <Box sx={{ textAlign: 'right', mb: 2, mr: 2 }}>
                                        <Button
                                            ref={selectButtonRef}
                                            variant="outlined"
                                            size="small"
                                            color="inherit"
                                            sx={{ fontWeight: "bold", minWidth: '44px', minHeight: '44px' }}  // Adjust button size
                                            onClick={handleSelectImages}
                                        >
                                            {MyAlbumPageStore.isSelectImagesMode ? "ביטול בחירת תמונות" : "בחירת תמונות"}
                                        </Button>
                                    </Box>
                                    <Box ref={imagesListRef}>
                                        <ImagesList handleImageClick={handleEnterFullScreen} />
                                    </Box>
                                </>
                            )
                        }
                    </Box>
                </Grid>
                <BottomFooter />
            </Grid>
            <MoreActionsDialog imagesListRef={imagesListRef} selectButtonRef={selectButtonRef} />
        </>
    );
});

export default MyAlbumPage;
