import { Box } from "@mui/material";
import React from "react";
import MemoraLoader from "../assets/images/loader.gif";

const Loader: React.FC<{}> = () => {
    return (
        <img src={MemoraLoader} style={{ width: "250px", height: "250px" }} alt="loader" />
    );
}

export default Loader;