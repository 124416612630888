import { makeAutoObservable } from "mobx";
import { AlbumImage } from ".";
import { downloadEventImagesAlbum } from "../../api";

export class MyAlbumPageStore {
    public pinCode: string;
    public showAlbumPage: boolean;
    public selectedImages: AlbumImage[];
    public expandImage: AlbumImage | null;
    public isMoreActionsDialogOpen: boolean;
    public isFullScreen: boolean;
    public images: AlbumImage[];
    public isGlobalDownloadLoading: boolean;
    public scrollPosition: number; // Added scrollPosition
    public isSelectImagesMode: boolean;

    public constructor() {
        this.pinCode = "";
        this.showAlbumPage = false;
        this.selectedImages = [];
        this.isMoreActionsDialogOpen = false;
        this.expandImage = null;
        this.isFullScreen = false;
        this.images = [];
        this.isGlobalDownloadLoading = false;
        this.scrollPosition = 0; // Initialize scrollPosition
        this.isSelectImagesMode = false;

        makeAutoObservable(this);
    }

    public get imagesCount(): number {
        return this.images.length;
    }

    public get imageNumber(): number {
        if (!this.expandImage) return 0;
        return this.images.findIndex(
            (image) => image.displayUrl === this.expandImage?.displayUrl
        ) + 1;
    }

    public async handleGlobalDownload(eventId: string, phoneNumber: string, pinCode: string): Promise<void> {
        this.isGlobalDownloadLoading = true;
        const allImagesKeys = this.images.map((image) => image.key);
        await downloadEventImagesAlbum(eventId, phoneNumber, pinCode, allImagesKeys);
        this.isGlobalDownloadLoading = false;
    }

    public setExpandImage(expandImage: AlbumImage | null): void {
        this.expandImage = expandImage;
    }

    public setIsSelectImagesMode(isSelectImagesMode: boolean): void {
        this.isSelectImagesMode = isSelectImagesMode;
    }

    public setImages(images: AlbumImage[]): void {
        this.images = images;
    }

    public setIsFullScreen(isFullScreen: boolean): void {
        this.isFullScreen = isFullScreen;
    }

    public setPinCode(pinCode: string): void {
        this.pinCode = pinCode;
    }

    public setShowAlbumPage(showAlbumPage: boolean): void {
        this.showAlbumPage = showAlbumPage;
    }

    public setSelectedImages(selectedImages: AlbumImage[]): void {
        this.selectedImages = selectedImages;
    }

    public selectImage(selectedImage: AlbumImage): void {
        const isSelected = this.selectedImages.find(
            (image) => image.displayUrl === selectedImage.displayUrl
        );

        if (isSelected) {
            // If the image is already selected, remove it
            this.selectedImages = this.selectedImages.filter(
                (image) => image.displayUrl !== selectedImage.displayUrl
            );
        } else {
            // If the image is not selected, add it to the selection
            this.selectedImages = [...this.selectedImages, selectedImage];
        }
    }

    public setIsMoreActionsDialogOpen(isMoreActionsDialogOpen: boolean): void {
        this.isMoreActionsDialogOpen = isMoreActionsDialogOpen;
    }

    public setScrollPosition(scrollPosition: number): void { // Added method to set scroll position
        this.scrollPosition = scrollPosition;
    }
}

export default new MyAlbumPageStore();
