import React from "react";
import { Button, CircularProgress, Box } from "@mui/material";

interface ActionButtonProps {
    label: string;
    onClick: () => void;
    Icon: React.ElementType;
    isLoading?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({ label, onClick, Icon, isLoading }) => {
    return (
        <Button
            variant="outlined"
            onClick={onClick}
            disabled={isLoading}
            sx={{
                position: "relative",
                borderRadius: "16px",
                height: "25px",
                width: "165px",
                padding: "6px 12px",
                color: "black",
                background: "#FFFFFF",
                overflow: "hidden",
                border: "1px solid transparent",
                backgroundImage: "linear-gradient(#FFFFFF, #FFFFFF), linear-gradient(157.4deg, #FF77C0, #8745F3, #70D4FF)",
                backgroundOrigin: "border-box",
                backgroundClip: "padding-box, border-box",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                sx: { minWidth: '44px', minHeight: '44px' } // Adjust button size
            }}
            startIcon={
                !isLoading && (
                    <Icon />
                )
            }
        >
            {isLoading ? (
                <CircularProgress
                    size={20} // Size of the spinner
                    sx={{
                        color: "black", // Color of the spinner
                    }}
                />
            ) : (
                label
            )}
        </Button>
    );
};

export default ActionButton;
