import { Box, Typography } from "@mui/material";
import noImagesFound from "../../../assets/images/no_images_found.png";

const NoImagesFound: React.FC<{}> = () => {
    return (
        <>
            <Box>
                <Typography variant="h5" textAlign="center" fontWeight="600">אופס...</Typography>
            </Box>
            <Box>
                <Typography variant="h6" textAlign="center"  >נראה שאין לך תמונות מהאירוע</Typography>
            </Box>
            <Box display="flex" justifyContent="center">
                <img src={noImagesFound} alt="No images found" />
            </Box>
        </>
    );
}

export default NoImagesFound;